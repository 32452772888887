.projects {
  color: var(--text-color);
  height: max-content;
}

/* the following target left and right divs */
.projects .exibit:nth-child(even) > div:first-child {
  width: 55%;
}
.projects .exibit:nth-child(even) > div:nth-child(2) {
  width: 45%;
}
.projects .exibit:nth-child(odd) > div:nth-child(2) {
  width: 55%;
}
.projects .exibit:nth-child(odd) > div:first-child {
  width: 45%;
}

.exibit {
  display: flex;
  margin-bottom: 150px;
  margin-left: 80px;
  margin-right: 80px;
}

.exibit > div {
  height: 29.5vw;
  /* max-height: 420px; */
  display: flex;
  position: relative;
  align-items: center;
  /* width: 100%; */
}

.exibit .right {
  justify-content: flex-end;
}

.exibit .board {
  position: absolute;
  top: 0;
  aspect-ratio: 2 / 1.2;
  width: 100%;
  cursor: pointer;
  background-color: var(--primary);
  background-color: var(--dark-1);
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  transition: background-color 0.5s;
}

.exibit .board img {
  height: 87.5%;
}

.exibit .info {
  width: 90%;
}

.exibit .right .date,
.exibit .right .title,
.exibit .right .about {
  text-align: right;
}

.exibit .left .date,
.exibit .left .title,
.exibit .left .about {
  text-align: left;
}

.exibit .date {
  margin-bottom: 20px;
}

.exibit .features {
  margin-bottom: 15px;
}

.fb-small_screen {
  position: absolute;
}

.exibit .board img.fb-small_screen {
  position: absolute;
  width: 32%;
  height: auto;
  bottom: -14px;
  right: 46px;
  border-radius: 6px;
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.263);
}

.exibit .board .fb-engagements {
  position: absolute;
  width: 44%;
  height: auto;
  bottom: 34px;
  right: 20px;
}

.exibit .board .overlay {
  background-color: var(--primary);
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0.5;
  transition: opacity 0.2s ease;
}

.exibit .board:hover .overlay {
  opacity: 0;
  transition: all 0.2s ease;
}

.exibit .board img {
  filter: grayscale(1);
  transform: scale(1.01);
  transition: all 0.2s ease;
}

.exibit .board:hover img {
  filter: grayscale(0) brightness(1);
  transform: scale(1);
  transition: all 0.2s ease;
}

.exibit .board:hover {
  background-color: var(--dark-1);
  transition: background-color 0.2s;
}

/* icons */
.icons {
  margin-top: 25px;
  display: flex;
  justify-content: space-between;
}

.icons .wrapper {
  display: flex;
  justify-content: space-between;
  width: 68px;
}

.icon {
  stroke: var(--dark-1);
  width: 26px;
  height: 26px;
  transition: stroke 0.2s;
}

.icon:hover {
  stroke: var(--primary);
  transition: stroke 0.2s;
}

@media screen and (max-width: 1300px) {
  section.projects {
    min-height: 1450px;
  }
}

@media screen and (min-width: 1080px) {
  .exibit:nth-child(even) .icons {
    justify-content: flex-end;
  }
}

@media screen and (max-width: 1080px) {
  section.projects {
    min-height: 1870px;
  }

  /* exibits  */
  .projects .exibit {
    flex-direction: column;
  }
  .projects .exibit:nth-child(even) {
    flex-direction: column-reverse;
  }
  .projects .exibit:nth-child(even) .right,
  .projects .exibit:nth-child(even) .left,
  .projects .exibit:nth-child(odd) .right,
  .projects .exibit:nth-child(odd) .left {
    justify-content: flex-start;
    height: max-content;
  }

  .projects .exibit:nth-child(even) .right,
  .projects .exibit:nth-child(odd) .left {
    margin-bottom: 10px;
  }
  .projects .exibit:nth-child(even) .right .info * {
    text-align: left;
  }

  .page .container .exibit .board {
    position: inherit;
  }
  .page .container .projects .exibit > div.left,
  .page .container .projects .exibit > div.right {
    width: 600px;
    max-width: 100%;
  }

  .page .container .projects .exibit:nth-child(even) .right {
    height: max-content;
  }
}

@media screen and (max-width: 935px) {
  section.projects {
    min-height: 1850px;
  }
  section.projects {
    min-height: 1750px;
  }
}

@media screen and (max-width: 820px) {
  .page .container .projects .exibit > div .info {
    width: 100%;
  }
}

@media screen and (max-width: 750px) {
  section.projects {
    min-height: 1750px;
  }
}

@media screen and (max-width: 720px) {
  .page .container .projects .exibit {
    margin-left: 0px;
    margin-right: 0px;
  }

  section.projects {
    width: calc(100% - 80px);
    margin: auto;
  }
}

@media screen and (max-width: 375px) {
  .page .container section.projects {
    width: calc(100% - 40px);
  }
}
@media screen and (max-width: 360px) {
  .page .container .projects .exibit {
    margin-left: 0px;
    margin-right: 0px;
    margin-bottom: 100px;
  }
}
